<template>
  <div>
    <div class="modal fade" id="modal-form-import-carga">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cargar Archivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal-import"
              ref="closeModalImport"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="btn btn-default image-preview-input col-md-10">
                <input
                  type="file"
                  id="file"
                  ref="file"
                  class="form-control btn btn-light"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="$v.form.file.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-2 float-left" v-if="form.file">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="limpiarArchivo"
                >
                  <i class="fas fa-eraser"></i>
                </button>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="form-group col-md-10">
                <div class="card card-outline card-navy">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <h6>
                        <i class="fas fa-file-signature"></i>
                        Nota:
                      </h6>
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <ol>
                        <li>
                          El formato debe estar completo sin ningún campo en
                          blanco.
                        </li>
                        <li>
                          No se puede cambiar el nombre de las pestañas de la
                          plantilla.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="btn btn-primary"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "CsSolicitudesNacionalesImport",
  components: {},

  data() {
    return {
      accion: 1,
      material_inventarios: null,
      cargue_id: null,
      solicitud: {},
      form: {
        file: null,
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },

  methods: {
    getModal(material_inventarios) {
      this.material_inventarios = material_inventarios;
    },

    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    importExcel() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("cs_solicitud_nacional_id", this.$parent.form.id);
      formData.append("material_inventarios", this.material_inventarios);
      axios
        .post(
          "/api/funcionariosFrontera/solicitudesNacionalesImport/importExcelTemp",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.status == 0) {
            this.$swal({
              icon: "error",
              title: res.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          } else {
            this.$refs.closeModalImport.click();
            this.limpiarModal();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se subió con éxito el archivo.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.$refs.CsSolicitudesNacionalesModal.detCargas =
              res.data;

            this.$parent.cargando = false;
          }
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "Ha ocurrido un error, por favor intente la acción nuevamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarArchivo() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },

    limpiarModal() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },
  },

  mounted() {},
};
</script>
